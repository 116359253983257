import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/src/components/ui/table";
import { Input } from "@/src/components/ui/input";
import { Variation_object } from '../addmodal';
import { Label } from '@/src/components/ui/label';
import { CustomSelectCustomOptionOption, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps, SelectCustomLoadingSearching } from '../../../../components/input_loading_searching';
import { pagination, ProductVariation, ProductVariationOption, ProductVariationOptionKeys } from '@/src/types';
import { useRESTapi } from '@/src/hooks/rest_API';


type props = {
    variation_objects: Variation_object[],
    set_variation_objects: (obj: Variation_object[]) => any,
    disabled?:boolean
}


const VariationComponentNew = (propsOG: props) => {

    const api = useRESTapi()

    const handleOptionChange = (index: number, value: string) => {
        const updatedObjects = [...propsOG.variation_objects];
        if (updatedObjects[index].vatiationOption) {
            updatedObjects[index].vatiationOption!.M07_Value = value;
            propsOG.set_variation_objects(updatedObjects);
        }
    };
    const load_variation_option: (props: loadOptionProps & ProductVariation) => Promise<loadOptionReturnProps<ProductVariationOption & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = '',
        M06_M04_CategoryID,
        M06_Name,
        M06_M11_ProductID,
        M06_VariationID
    }) => {
        if (!M06_M11_ProductID || !M06_VariationID) {
            alert('no variation or product not found')
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: ProductVariationOption[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variation_options`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                variation_id: M06_VariationID,
                ProductID: M06_M11_ProductID
            },
            mode: 'admin',

        })




        if (res.data.success === true) {

            const pagination_return: pagination = {
                per_page: res.data.data.per_page,
                total: res.data.data.total
            }
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M07_VariationOptionID!,
                            name: item.M07_Value
                        } satisfies CustomSelectCustomOptionOption & ProductVariationOption
                    }
                ),
                pagination: pagination_return
            }

        } else {
            alert('error laoding variation option')
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }


    }

    const fetch_selected_variation_option: (props2: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<ProductVariationOption & CustomSelectCustomOptionOption>> = async (props2: loadSelectedOptionProps) => {
        if (props2.value) {
            const res = await api.get<{ success: boolean, data: ProductVariationOption, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variation_options/${props2.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M07_Value,
                        value: res.data.data.M07_VariationOptionID,

                    } as (ProductVariationOption & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }

    const create_option = async (variation_option: ProductVariationOption) => {

        const formDataReal = new FormData();
        formDataReal.append('M07_M06_VariationID', String(variation_option.M07_M06_VariationID))
        formDataReal.append('M07_Value', variation_option.M07_Value)
        formDataReal.append('M07_M011_ProductId', String(variation_option.M07_M011_ProductId))
        const res = await api.post<{ success: boolean, data: ProductVariationOption, errors: any, message: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variation_options`,
            body: formDataReal,
            mode: 'admin',

        })

        if (res.data.success === false) {


            throw new Error('error creating variation option')
        } else {

            // alert(res.data.message)
            return res.data.data
        }

    }



    return (
        propsOG.variation_objects.length ?
            <div className=''>
                <Label>Variation</Label>
                <div className=' h-2'></div>
                <Table className=' border rounded-md w-min'>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Variation</TableHead>
                            <TableHead>Option</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody >
                        {propsOG.variation_objects.map((obj, index) => {
                            return (
                                <>
                                    <TableRow className={` ${obj.error?'bg-red-500/10 hover:bg-red-500/10 ':''}`} key={index}>
                                        <TableCell>
                                            <Input
                                                className={` max-w-[200px] min-w-[100px]`}
                                                value={obj.variation?.M06_Name || ''}
                                                disabled
                                            />

                                        </TableCell>
                                        <TableCell>
                                            {/* <Input
                                    value={obj.vatiationOption?.M07_Value || ''}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                /> */}
                                            <SelectCustomLoadingSearching<ProductVariationOption & CustomSelectCustomOptionOption>
                                                loadOption={(props) => load_variation_option({
                                                    ...props,
                                                    ...obj.variation!
                                                })}
                                                fetchSelectOption={fetch_selected_variation_option}
                                                name={ProductVariationOptionKeys.M07_VariationOptionID}
                                                placeholder='option'
                                                className='grow'
                                                value={obj.vatiationOption?.M07_VariationOptionID}
                                                onselect={(e) => {
                                                    console.log(e)
                                                    propsOG.variation_objects[index] = {
                                                        variation: { ...propsOG.variation_objects[index].variation! },
                                                        vatiationOption: e.target.object,
                                                        // mode: 'create',
                                                        source: 'local'
                                                    }
                                                    propsOG.set_variation_objects([...propsOG.variation_objects])
                                                    console.log(propsOG.variation_objects)
                                                }}
                                                disable={propsOG.disabled}
                                                customeComponent={
                                                    (props2) => {
                                                        if (props2.options.length === 0 && props2.searchQuery) {
                                                            return (
                                                                <div onClick={async () => {

                                                                    props2.start_loading()
                                                                    await create_option({
                                                                        M07_M06_VariationID: obj.variation?.M06_VariationID,
                                                                        M07_Value: props2.searchQuery!
                                                                    }).then(
                                                                        option => {
                                                                            props2.end_laoding()
                                                                            props2.close_menu()
                                                                        }
                                                                    ).catch(
                                                                        err => {
                                                                            alert('something went wrong')
                                                                            props2.end_laoding()
                                                                            props2.close_menu()
                                                                            console.log(err)
                                                                        }
                                                                    )
                                                                }} className=' p-2 w-full hover:cursor-pointer hover:bg-slate-400'>
                                                                    create <span className=' font-bold'>{props2.searchQuery}</span>
                                                                </div>
                                                            )
                                                        }
                                                        return null
                                                    }
                                                }

                                            />

                                        </TableCell>
                                    </TableRow>
                                    {
                                        obj.error ?
                                            <TableRow >
                                                <TableCell className=' px-1 py-1 bg-red-500/10 hover:bg-red-500/10 text-center font-bold text-xs text-red-500 ' colSpan={2}>{obj.error}</TableCell>
                                            </TableRow>
                                            :null
                                }
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </div> :
            <div></div>
    );
};

export { VariationComponentNew };