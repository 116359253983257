
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useConfiguration } from "src/hooks/configuration_hook";
import { useRESTapi } from "src/hooks/rest_API";
import { ProductCategory, ProductItem, ProductPrice, pagination } from 'src/types';
import Footer from '../../components/Footer/Footer';
import { ProductCard, ProductCardSkeleton } from "../../components/product/productCard";
import { MultiRangeSlider } from '../../components/sliders/doublesideSlider';
import { ProductPagination } from "../../components/Pagination/ProductPagination";
import { useAlertModal } from "@/src/hooks/alert_modal";
import { Button } from '@/src/components/ui/button';
import { Helmet } from 'react-helmet-async';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

type ProductItemOverview = ProductItem & {
    price: ProductPrice, customer_stock_quantity: number
}

export function CategoryInner({ categoryId }: { categoryId: string }) {

    const [open, setOpen] = React.useState(false);
    const {
        set_category_tab_theme
    } = useConfiguration()

    const handleOpen = () => setOpen(!open);
    const alertModal = useAlertModal()
    const [catogery, setCategory] = useState<ProductCategory>()
    const api = useRESTapi()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [range, setRange] = useState<number[]>([50, 100]); // Initial range values
    const [product_skus, set_product_skus] = useState<ProductItemOverview[]>([]);
    const [is_loading, set_is_loading] = useState(false)
    const [paginationParams, setPaginationParams] = useState({
        pageNo: 1,
        totalData: 10,
        dataPerPage: 10
    })
    const { ref, inView } = useInView();

    //URLParams
    const urlParams = {
        searchParams: searchParams.get('searchString')
    }

    const handleSliderChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRange = [...range];
        newRange[index] = parseInt(e.target.value, 10);
        setRange(newRange);
    };

    const loadProducts = async (page: any) => {
        try {
            set_is_loading(true)
            const res = await api.get<{
                success: boolean, data: {
                    products: ({
                        data: ProductItemOverview[]
                    }) & pagination,
                    category: ProductCategory
                }
                , message: string,
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/productitem_category`,
                mode: 'customer',
                params: {
                    categoryId: categoryId,
                    searchItem: urlParams.searchParams,
                    perPage: paginationParams.dataPerPage,
                    page: page.pageParam
                }
            })

            if (res.data.success === true) {
                set_product_skus(res.data.data.products.data)
                setCategory(res.data.data.category)
                set_is_loading(false)
                setPaginationParams({
                    ...paginationParams,
                    totalData: res.data.data.products.total
                })
                set_is_loading(false)
                return res.data.data.products
            } else {
                alertModal.open_alert_modal({
                    content: res.data.message,
                    footer: <Button onClick={() => {
                        navigate('/')
                        alertModal.close_alert_modal()
                    }}>Ok</Button>,
                    title: 'Error'
                })
            }
        } catch (error) {
            alertModal.open_alert_modal({
                content: 'server error',
                footer: <Button onClick={() => {
                    navigate('/')
                    alertModal.close_alert_modal()
                }}>Ok</Button>,
                title: 'Error'
            })
        }

    }



    const sortOptions = [
        'Popularity',
        'Price-Low to High',
        'Price-High to Low',
        'Newest First'
    ];
    const [activeTab, setActiveTab] = useState('Popularity');


    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    useEffect(
        () => {

            window.scrollTo({ top: 0 })
            set_category_tab_theme({ theme: 'colorful' })

        }, [paginationParams.pageNo, categoryId]
    )


    const {
        data,
        error,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
        isFetching,
    } = useInfiniteQuery(
        {
            queryKey: ['products-category',categoryId,urlParams.searchParams],
            queryFn: loadProducts,
            getNextPageParam: (lastPage, allPages) => {

                if (lastPage)
                    return (lastPage.last_page!) <= lastPage.current_page! ? undefined : lastPage.current_page! + 1;
            },
            initialPageParam: 1,
        }
    )

    useEffect(
        () => {
            if (hasNextPage && inView && !isLoading && !isFetchingNextPage) {
                fetchNextPage()
            }
        }, [inView]
    )


    return (
        <div key={catogery?.M04_ProductCategoryId} className='bg-blue-gray-100 relative pt-1'>

            <Helmet>
                <title>{catogery?.M04_ProductCategoryMetaTitle?.toLowerCase()}</title>
                <meta name="description" content={catogery?.M04_ProductCategoryMetaDescription} />
                <link rel="canonical" href={window.location.href} />

            </Helmet>

            {/* <CarouselProducts/> */}

            <div className="circleblur bg-[#263238] w-28 h-28 top-28 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>
            <div className="circleblur bg-[#263238] w-28 h-28 bottom-28 right-0 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>



            <div className=" px-2 pt-8 py-2 font-bold text-center text-2xl">{catogery ? catogery.M04_ProductCategoryName : ''}</div>
            <div style={{ scrollbarWidth: 'none' }} className="mainProducts flex flex-wrap gap-4 p-6  justify-items-center  overflow-x-scroll justify-center" >



                {
                    isLoading === false
                    &&
                        data?.pages?.[0]?.data?.length === 0  ?
                            <div className="flex flex-col items-center justify-center h-full text-center text-gray-700 py-28">
                                <div className="mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-16 h-16 text-gray-500"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 3h18M9 3v18m6-18v18m6-6H3"
                                        />
                                    </svg>
                                </div>
                                <h1 className="text-2xl font-semibold mb-2">No Products Found</h1>
                                <p className="text-gray-500">We couldn't find any products matching your search.</p>
                            </div>
                            :
                            data?.pages?.map(
                                page=> page?.data.map((product, index) => (

                                <ProductCard
                                    key={product.M08_ProductItemID}
                                    MRPPrice={Number(product.price?.M10_MRP_price)}
                                    listPrice={Number(product.price?.M10_List_price)}
                                    description={product.M08_Description}
                                    imagePath={`${process.env.REACT_APP_BACKEND_URL}/${product.M08_ProductImage}`}
                                    productId={String(product.M08_ProductItemID)}
                                    title={product.M08_ProductItemName}
                                    productsku={product.M08_SKU as string}
                                    alt={product.M08_alt_text||''}

                                    onclick={
                                        () => {
                                            const name = product.M08_ProductItemName.replace(/ /g, '-').toLowerCase();
                                            window.open(`/${name}/product/?productItemId=${product.M08_ProductItemID}`, '_blank')

                                        }
                                    }
                                    isCustomizationAvailable={product.M08_is_customization_available}
                                    quantity={product.customer_stock_quantity}
                                />
                                )))
                                
                            
                }
                {
                    isFetching ?
                        Array(10).fill(15).map(
                            (any, index) => <>
                                    <ProductCardSkeleton />
                            </>
                        ) : null
                }

            </div>
            <div ref={ref } className=" relative bottom-12"/>
            {/* <div className=" flex justify-end p-2 z-10 relative">
                <ProductPagination
                    totalRows={paginationParams.totalData}
                    onClickIndex={(index) => {
                        setPaginationParams(
                            {
                                ...paginationParams,
                                pageNo: index
                            }
                        )
                    }}
                    rowsPerPage={paginationParams.dataPerPage}
                    selectedPage={paginationParams.pageNo}
                />
            </div> */}
            <Footer />

        </div>
    )

}

export function CategoryProductsPage() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const categoryId = urlParams.get('categoryId');
    return (
        <CategoryInner categoryId={categoryId!} key={categoryId} />
    )
}
