// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CustomServiceButton: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    const navigate = useNavigate()


    const handleClose = () => {
        setIsOpen(false);
    };
    
    return (
        <div className=' fixed bottom-3 sm:bottom-28  right-2 z-[500000]'>
            <div
                className="relative inline-block "
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                {<AnimatePresence>
                    {isOpen ? (
                        <>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ duration: 0.2 }}
                                className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-6 rounded-xl shadow-lg cursor-pointer"
                                onClick={() => navigate('/custom-service')}
                            >
                                <p className="mb-4 text-lg font-semibold">Need other services? Click here</p>
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    onClick={handleClose}
                                    className="absolute top-3 right-3 text-white hover:text-gray-200 focus:outline-none"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </motion.button>
                            </motion.div>
                        </>
                    ) : (
                        <>
                            <motion.button
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.2 }}
                                whileHover={{ scale: 1.05, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}
                                whileTap={{ scale: 0.95 }}
                                className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white px-6 py-3 rounded-full shadow-md text-lg font-semibold transform transition-all duration-200 ease-in-out"
                            >
                                Custom Service
                            </motion.button>
                        </>
                    )}
                </AnimatePresence> as any}
            </div>
        </div>
    );
};

export default CustomServiceButton;