import { StarIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCartApi } from 'src/hooks/cart_api';
import { useConfiguration } from 'src/hooks/configuration_hook';
import { SKUCompleteDetailsType, useProductSKU } from 'src/hooks/product_sku';
import { RootState } from 'src/redux/store';
import { Slider } from './components/Slides';
import { CustomizationComponent } from './components/customization';
import { CustomizationOption, CustomizationOptionStored } from 'src/types/local_storage';
import { ProductItemHighlights } from '@/src/types';
import Footer from '../../components/Footer/Footer';
import { ProductCategoryIds } from '@/src/types/databaseFeeds';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { Button } from '@/src/components/ui/button';
import ProductVariationSelector from './components/ProductVariationSelector';
import { ProductSkeleton } from './components/skelton';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { ButtonIcon, WhatsappHover } from '@/src/components/buttons/buttonIcon';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


interface customization {
    customizationId: string,
    name: string,
    unit: string,
    unitPrice: number
}

interface ProductSKU {
    productId: string;
    itemId: string;
    sku: string;
    name: string;
    price?: number;
    MRPPrice?: number;
    // href: string;
    breadcrumbs: Breadcrumb[];
    images: Image[];
    description: string;
    // highlights: string[];
    // details: string;
    variation: Variation[],
    variationOg: Variation3[],
    customzations: CustomizationOption[],
    isCustomizationAvailable: boolean,
    quantity: number
    highlites: ProductItemHighlights[],
    categoryID: string,
    otherVarients: ({
        productItemId: number,
        options: (number)[]
    })[],
    meta_tags: string,
    metadescription: string

}


interface VariationOption {
    optionId: string,
    optionName: string,
    isSelected: boolean
}

interface Variation {
    variationId: string;
    variationName: string;
    variationOption: VariationOption
}

interface Breadcrumb {
    id: number;
    name: string;
    href: string;
}

interface Image {
    src: string;
    alt: string;
}
interface VariationOption2 {
    M07_VariationOptionID: number;
    M07_Value: string;
    M07_M06_VariationID: number;
    M07_M11_ProductID: number;
}

interface Variation2 {
    M06_VariationID: number;
    M06_M04_CategoryID: number;
    M06_Name: string;
    M06_M11_ProductID: number;
    options: VariationOption2[];
}
interface Variation3 {
    M06_VariationID: number;
    M06_M04_CategoryID: number;
    M06_Name: string;
    M06_M11_ProductID: number;
    variation_option: VariationOption2
}
const reviews = { href: '#', average: 4, totalCount: 117 }

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}



export function ProductPage() {


    const location = useLocation();
    const productLink = window.location.href;
    const [productSKU, setProductSKU] = useState<ProductSKU>()
    const [allVariation, setAllVariations] = useState<Variation2[]>()

    const urlParams = new URLSearchParams(location.search);
    const productItemId = urlParams.get('productItemId');

    const { name: productName } = useParams()
    const {
        get_full_sku_details_by_id,
        get_variations_by_sku_id
    } = useProductSKU()

    const { close_alert_modal, open_alert_modal } = useAlertModal()

    const {
        set_category_tab_theme
    } = useConfiguration()
    const { add_to_Cart, remove_from_Cart } = useCartApi()
    const cart = useSelector((state: RootState) => state.cart)
    const customer = useSelector((state: RootState) => state.customer.customerData)
    const currency = useSelector((state: RootState) => state.configuration.currencyDetails)
    const navigate = useNavigate()
    const [is_loading, set_is_loading] = useState(false)
    const [is_customiation_valid, set_is_customization_valid] = useState<boolean>(false)
    const [customization_options, set_customization_option] = useState<CustomizationOptionStored[]>([])


    const loadSKUDetails = () => {

        if (productItemId) {
            get_full_sku_details_by_id({
                productId: String(productItemId)
            }).then(
                res => {
                    const productOgSKU = res as SKUCompleteDetailsType
                    if (productOgSKU.categoryID == ProductCategoryIds.services) {
                        open_alert_modal({
                            content: 'this is service product',
                            footer: <Button onClick={close_alert_modal}>Close</Button>,
                            title: 'Service Product'
                        })
                        navigate('/')
                    }
                    const productDetails: ProductSKU = {
                        productId: String(productOgSKU.M08_M11_ProductID),
                        categoryID: String(productOgSKU.categoryID),
                        itemId: String(productOgSKU.M08_ProductItemID),
                        breadcrumbs: [
                            { href: '/', id: 1, name: 'Home' },
                            { href: '', id: 2, name: productOgSKU.M08_ProductItemName },
                        ],
                        description: productOgSKU.M08_Description,
                        images: productOgSKU.images ?
                            productOgSKU.images.map(
                                image => ({
                                    alt: image.M18_altText||'product image',
                                    src: `${process.env.REACT_APP_BACKEND_URL!}/${image.M18_ImagePath}`
                                })
                            ) :
                            [],
                        name: productOgSKU.M08_ProductItemName,
                        price: Number(productOgSKU.M09_List_price?.M10_List_price),
                        MRPPrice: Number(productOgSKU.M09_List_price?.M10_MRP_price),
                        sku: productOgSKU.M08_SKU as string,
                        variation: productOgSKU.variations.map(
                            option => ({
                                variationId: String(option.M06_VariationID),
                                variationName: option.M06_Name,
                                variationOption: {
                                    isSelected: true,
                                    optionId: String(option.variation_option.M07_VariationOptionID),
                                    optionName: option.variation_option.M07_Value
                                }
                            })
                        ),
                        variationOg: productOgSKU.variations as any,
                        customzations: productOgSKU.customization ? productOgSKU.customization.customizationOptions.map(
                            customization => ({
                                id: String(customization.M22_customization_option_id),
                                name: customization.M22_customization_name,
                                value: customization.M22_value,
                                type: customization.M22_type,
                                varientID: customization.M22_varientID,
                                isSlab: customization.M22_isSlab === 1 ? true : false,
                                rate: Number(customization.M22_rate),
                                hikePerc: Number(customization.M22_hikePerc),
                                hikePercInRate: Number(customization.M22_hikePercInRate),
                                minVal: Number(customization.M22_minVal),
                                maxVal: Number(customization.M22_maxVal),
                                order: customization.M22_order,
                                baseRate: customization.M22_baseRate,
                                isDefault: customization.M22_isDefault === 1 ? true : false,
                                optionId: String(customization.M22_M21_product_customization_id)
                            } satisfies CustomizationOption)
                        ) : [],
                        isCustomizationAvailable: productOgSKU.M08_is_customization_available === 0 ? false : true,
                        quantity: productOgSKU.customer_stock_quantity,
                        highlites: productOgSKU.highlights,
                        otherVarients: productOgSKU.otherVarients,
                        meta_tags: productOgSKU.metadescription || productOgSKU.M08_meta_tags || '',
                        metadescription: productOgSKU.metadescription || productOgSKU.M08_meta_description || '',

                    }
                    setProductSKU(productDetails)
                    get_variations_by_sku_id({ id: productDetails.productId }).then(
                        variations => {
                            setAllVariations(variations)
                            console.log(`variations = ${variations}`);
                        }
                    )

                }
            )
        }
    }


    useEffect(
        () => {
            window.scrollTo({ top: 0 })

            if (productItemId !== 'null') {
                set_category_tab_theme({ theme: 'colorful' })
                setProductSKU(undefined)
                setAllVariations(undefined)
                loadSKUDetails()
            }
            const handleContextmenu = (e: any) => {
                e.preventDefault()
            }
            document.addEventListener('contextmenu', handleContextmenu)
            return function cleanup() {
                document.removeEventListener('contextmenu', handleContextmenu)
            }
        }, [productItemId]
    )
    return (

        productSKU
            ?
            <div className=" bg-blue-gray-100 ">
                <Helmet>
                    <title>{productSKU.meta_tags?.toLowerCase() || productSKU.name?.toLowerCase()}</title>
                    <meta name="description" content={productSKU.metadescription || productSKU.description} />
                    <link rel="canonical" href={window.location.href} />

                </Helmet>
                <div className="circleblur bg-[#263238] w-28 h-28 top-28 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>
                <div className="circleblur bg-[#263238] w-28 h-28 bottom-28 right-0 blur-2xl fixed animate-pulse" style={{ animationDuration: '10s' }}></div>
                <div className=' max-w-[1400px] mx-auto relative z-50'>
                    <div className=' md2:flex-row flex-col flex justify-end px-4 lg:px-16 py-6'>
                        <Slider slides={productSKU.images} thumbnails={productSKU.images.map(image => image.src)} />
                        <div className="md2:pl-6 grow lg:grid md2:max-w-[60%] lg:grid-cols-1 lg:grid-rows-[auto,auto,1fr]  gap-2">
                            <div className="lg:col-span-2">
                                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl break-words hyphens-auto">
                                    {productSKU.name}
                                </h1>
                            </div>


                            {/* Options */}
                            <div className="mt-4 lg:row-span-3 lg:mt-0 gap-2">
                                <h2 className="sr-only">Product information</h2>
                                {
                                    productSKU.MRPPrice && productSKU.price ?
                                        <div className="flex flex-col ">
                                            <p className="text-xl tracking-tight text-gray-500 line-through">{currency.M05_CurrencySymbol} {productSKU.MRPPrice}</p>
                                            <p className="text-2xl tracking-tight text-gray-900 font-bold"><span className=''>{currency.M05_CurrencySymbol}</span> {productSKU.price}</p>
                                            <p className="text-sm tracking-tight text-black"> Savings: <span className=''>{currency.M05_CurrencySymbol}</span>  {Math.round(productSKU.MRPPrice - productSKU.price)}  <span className='bg-[#dff1d9] font-bold text-[#3baf07] ml-2 p-[2px] rounded-sm'> {Math.round(((productSKU.MRPPrice - productSKU.price) / productSKU.MRPPrice) * 100)}% off </span></p>
                                        </div> : null
                                }


                                {/* card */}

                                {/* Reviews */}
                                {/* <div className="mt-6">
                                    <h3 className="sr-only">Reviews</h3>
                                    <div className="flex items-center">
                                        <div className="flex i tems-center">
                                            {[0, 1, 2, 3, 4,].map((rating) => (
                                                <StarIcon
                                                    key={rating}
                                                    className={classNames(
                                                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                                                        'h-5 w-5 flex-shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            ))}
                                        </div>
                                        <p className="sr-only">{reviews.average} out of 5 stars</p>
                                        <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                            {reviews.totalCount} reviews
                                        </a>
                                    </div>
                                </div> */}

                                {/*    VARIATION SELECTOR  */}
                                {allVariation !== undefined && productSKU.variation.length > 0 && <ProductVariationSelector

                                    skuCombinations={productSKU.otherVarients}
                                    allVariations={allVariation}
                                    selectedSku={productSKU.variationOg}
                                    onChangeVariation={(id) => {
                                        if (id) {
                                            const name = productSKU.name.replace(/ /g, '-').toLowerCase();

                                            navigate(`/${name}/product/?productItemId=${id}`, { replace: true })
                                        }
                                    }}
                                />}

                                {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                                <form className="mt-10" onSubmit={e => e.preventDefault()}>


                                    {/* Sizes */}

                                    {
                                        // productSKU.variation.map(
                                        //     variation => (
                                        //         <div key={variation.variationId} className='mt-4'>
                                        //             <div className="flex items-center justify-between ">
                                        //                 <h3 className="text-sm font-medium text-gray-900">{variation.variationName}</h3>
                                        //             </div>
                                        //             <div className='flex gap-2 mt-2'>

                                        //                 <label key={variation.variationOption.optionId} className={classNames(
                                        //                     'cursor-pointer',
                                        //                     true ? 'bg-white text-gray-900 shadow-sm' : 'cursor-not-allowed bg-gray-50 text-gray-200',
                                        //                     'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6',
                                        //                     'max-w-16   max-h-12' // Add this line to set width and height
                                        //                 )}>
                                        //                     <input
                                        //                         type="radio"
                                        //                         value={variation.variationOption.optionName} // or any unique identifier for the size
                                        //                         disabled={false}
                                        //                         // onChange={() => setSelectedSize(size)}
                                        //                         checked={variation.variationOption.isSelected}
                                        //                         className="sr-only" // Hide the default radio button appearance
                                        //                     />
                                        //                     {variation.variationOption.optionName}
                                        //                 </label>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // )
                                    }


                                    {
                                        productSKU.isCustomizationAvailable === true && productSKU.MRPPrice && productSKU.price
                                            ?
                                            <CustomizationComponent
                                                onChangeValues={(masterObj, isEverythingValid) => {
                                                    set_is_customization_valid(isEverythingValid)
                                                    const optionsMain: CustomizationOptionStored[] = []
                                                    masterObj.dropDowns.forEach(
                                                        dropDown => {
                                                            dropDown.options.forEach(
                                                                options => {
                                                                    if (options.varientID == dropDown.selectedVarientId) {
                                                                        optionsMain.push({
                                                                            ...options,
                                                                            Inputvalue: 0
                                                                        })
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    )
                                                    masterObj.normals.forEach(
                                                        options => {
                                                            optionsMain.push({
                                                                ...options,
                                                                Inputvalue: Number(options.Inputvalue)
                                                            })
                                                        }
                                                    )
                                                    set_customization_option(optionsMain)
                                                }}
                                                basePrice={productSKU.price}
                                                options={productSKU.customzations} />
                                            : null
                                    }
                                    <div className='text-bold my-2 font-bold text-gray-800'>Sku: <span className=' font-medium'>{productSKU.sku}</span></div>
                                    {productSKU.quantity != 0 ?
                                        <div className={`font-bold  p-[2px] rounded-sm w-fit ${productSKU.quantity <= 10 ? 'bg-[#f1a9a9] text-red-500' : 'bg-[#dff1d9]  text-[#3baf07] '}`}>
                                            Available Quantity: {productSKU.quantity}
                                        </div> : null
                                    }



                                    {productSKU.quantity <= 0 && productSKU.isCustomizationAvailable === false && productSKU.MRPPrice && productSKU.price ? <div className=' text-red-700'>Out of stock</div> : null}

                                    {
                                        !productSKU.MRPPrice || !productSKU.price ?
                                            <button
                                                
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    const name = productSKU.name.replace(/ /g, '-').toLowerCase();
                                                    const whatsappLink = `https://wa.me/971569186717?text=${encodeURIComponent(
                                                        `Hello, I'm interested in this product and would like to inquire more about it. Here are the details:\n\n` +
                                                        `Product Link: ${process.env.REACT_APP_FRONTEND_URL}/${name}/product/?productItemId=${productSKU.itemId}\n\n` +
                                                        `Product SKU: ${productSKU.sku}\n\n` +
                                                        `Could you please provide more information about the availability, pricing, and delivery options?`
                                                    )}`
                                                    window.location.href = whatsappLink
                                                }}

                                                className={`mt-10 flex w-full items-center justify-center rounded-md border border-transparent cursor-pointer ${is_loading ? 'bg-green-500 hover:bg-green-400 cursor-default' : 'bg-green-500 hover:bg-green-400  cursor-default'}   px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}
                                            >
                                                Contact Whatsapp
                                            </button>
                                            :
                                            <div className=' w-full flex gap-2 fixed bottom-1 md2:relative right-0 left-0 px-2 md2:px-0'>
                                                {


                                                    cart.cart.some(item => item.productItemId == productSKU.itemId!)
                                                        ?
                                                        <button
                                                            disabled={productSKU.quantity <= 0 && productSKU.isCustomizationAvailable === false}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                set_is_loading(true)
                                                                if (is_loading === false)
                                                                    remove_from_Cart(
                                                                        {
                                                                            productItemId: String(productSKU.itemId),
                                                                            customerId: String(customer.M01_CustomerID)
                                                                        }
                                                                    ).then(res => set_is_loading(false))
                                                            }}

                                                            className={`mt-10 flex w-full items-center justify-center rounded-md border border-transparent ${is_loading ? 'bg-primary-400 hover:bg-primary-400 cursor-default' : 'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400 disabled:hover:bg-primary-400 cursor-default'}   px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}
                                                        >
                                                            Remove from Cart
                                                        </button>
                                                        :
                                                        productSKU.isCustomizationAvailable === true
                                                            ?
                                                            <button
                                                                disabled={!is_customiation_valid}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    set_is_loading(true)
                                                                    if (is_loading === false)
                                                                        add_to_Cart({
                                                                            customerId: String(customer.M01_CustomerID),
                                                                            productItemId: String(productSKU.itemId),
                                                                            quantity: 1,
                                                                            isActive: 1,
                                                                            isCustomizationAvailable: productSKU.isCustomizationAvailable ? 1 : 0,
                                                                            customizationOption: customization_options
                                                                        }).then(res => set_is_loading(false))
                                                                }}
                                                                className={`mt-10 flex w-full disabled:bg-primary-300 items-center justify-center rounded-md border border-transparent ${is_loading ? 'bg-primary-400 hover:bg-primary-400 cursor-default' : 'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400 disabled:cursor-not-allowed disabled:hover:bg-primary-400 cursor-default'}   px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}
                                                            >
                                                                Add to Cart
                                                            </button>
                                                            :
                                                            <button
                                                                disabled={productSKU.quantity <= 0}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    set_is_loading(true)
                                                                    if (is_loading === false)
                                                                        add_to_Cart({
                                                                            customerId: String(customer.M01_CustomerID),
                                                                            productItemId: String(productSKU.itemId),
                                                                            quantity: 1,
                                                                            isActive: 1,
                                                                            isCustomizationAvailable: productSKU.isCustomizationAvailable ? 1 : 0
                                                                        }).then(res => set_is_loading(false))
                                                                }}
                                                                className={`mt-10 flex w-full items-center justify-center rounded-md border border-transparent ${is_loading ? 'bg-primary-400 hover:bg-primary-400 cursor-default' : 'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-400  disabled:hover:bg-primary-400 disabled:cursor-not-allowed cursor-default'}   px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}
                                                            >
                                                                Add to Cart
                                                            </button>
                                                }

                                                {
                                                    productSKU.isCustomizationAvailable === true
                                                        ?
                                                        <button disabled={!is_customiation_valid} onClick={() => navigate(`/checkoutProduct/${productSKU.itemId}`, {
                                                            state: {
                                                                customizationObjs: customization_options
                                                            }
                                                        })} className={`mt-10 flex w-full items-center justify-center rounded-md border border-transparent disabled:bg-primary-400 disabled:hover:bg-primary-400 disabled:cursor-not-allowed cursor-default bg-primary-600 hover:bg-primary-700   px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}>Buy</button>
                                                        :
                                                        <button disabled={productSKU.quantity <= 0} onClick={() => navigate(`/checkoutProduct/${productSKU.itemId}`)} className={`mt-10 flex w-full items-center justify-center rounded-md border border-transparent disabled:bg-primary-400 disabled:hover:bg-primary-400 disabled:cursor-not-allowed cursor-default bg-primary-600 hover:bg-primary-700  px-8 py-3 text-base font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2`}>Buy</button>
                                                }

                                            </div>}
                                </form>
                            </div>

                            <div onCopy={(e) => e.preventDefault()} id='descriptionProduct' className="py-10 lg:col-span-2 select-none lg:col-start-1 lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                                {/* Description and details */}
                                <div>
                                    <h3 className="font-bold text-gray-600">Description</h3>

                                    <div className="space-y-6">
                                        <p className="text-base text-gray-900 whitespace-pre-line">{productSKU.description}</p>
                                    </div>

                                </div>
                                {/* highlites */}
                                <div className="mt-5">
                                    {productSKU.highlites.length > 0 && <h3 className="font-bold text-gray-600">Highlights</h3>}

                                    <div className="space-y-6">
                                        <ul className="list-disc pl-5 text-gray-600">
                                            {productSKU.highlites.map(highlight => (
                                                <li key={highlight.M24_highlight_id}>
                                                    <p className="text-base text-gray-900">{highlight.M24_highlight_text}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>


                                </div>

                                {/* <div className="mt-10">
                                    <h2 className="text-sm font-medium text-gray-900">Details</h2>

                                    <div className="mt-4 space-y-6">
                                        <p className="text-sm text-gray-600">{productSKU.details}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>




                    {/* Product info */}

                </div>
                {/* whatsapp icon  */}
                <WhatsappHover
                    productsku={productSKU.sku}
                    productLink={productLink}
                />
                <Footer />
            </div>
            :
            <>
                <ProductSkeleton />
            </>
        // <div className='pt-2'>Loading ...</div>
    )
}

































{/* <nav aria-label="Breadcrumb">
          <ol role="list" className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-5 w-4 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav> */}

{/* Image gallery */ }
{/* <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={product.images[0].src}
              alt={product.images[0].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[1].src}
                alt={product.images[1].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[2].src}
                alt={product.images[2].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img
              src={product.images[3].src}
              alt={product.images[3].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div> */}