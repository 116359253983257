import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CarouselCRUD } from './carousel/page'
import { RightCarouselCRUD } from './right_carousel/page'
import { BlogsCrud } from './blogs/page'
import { useCheckUserType } from '@/src/hooks/check_user'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'
type Props = {
  setSelectedId: (id: string) => any
}

export function PromotionRoot(props: Props) {
  const { isSEOs } = useCheckUserType()
  const employee = useSelector((state: RootState) => state.employee)

  return (
    <Routes>
      {!isSEOs(employee?.employeeData?.M15_Employee_id!) &&
        <>
          <Route index element={<Navigate to='carousel' />} />
          <Route path='carousel' element={<CarouselCRUD setSelectedId={props.setSelectedId} />} />
          <Route path='rightcarousel' element={<RightCarouselCRUD setSelectedId={props.setSelectedId} />} />
        </>
      }
      <Route path='blogs' element={<BlogsCrud setSelectedId={props.setSelectedId} />} />

    </Routes>
  )
}
